import React, { FC } from "react";

const PlaygroundView: FC = () => {
  return (
    <div>
      <p>PlaygroundView</p>
    </div>
  );
};

export default PlaygroundView;
